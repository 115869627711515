



















































import {Component, Vue} from 'vue-property-decorator';
import {namespace} from 'vuex-class';
import Customer from '@/models/Customer';
import SideCardComponent from '@/components/shared/SideCard.component.vue';

const CustomerStore = namespace('customer');

@Component({
  components: {
    SideCardComponent,
    CustomerManageComponent: () => import(
        /* webpackChunkName: "CustomerManageComponent" */
        '@/components/customer/CustomerManage.component.vue'),
    ImportComponent: () => import(
        /* webpackChunkName: "ImportCustomersComponent" */
        '@/components/shared/Import.component.vue'),
    CustomerTableComponent: () => import(
        '@/components/customer/CustomerTable.component.vue'),
    SearchBarComponent: () => import(
        '@/components/shared/SearchBar.component.vue'),
    ToggleButtonGroupComponent: () => import(
        '@/components/shared/ToggleButtonGroup.component.vue'),
  },
})
export default class CustomersOverviewView extends Vue {

  @CustomerStore.Action('loadCustomersAction')
  private loadCustomersAction!: (tenantId: string) => Promise<Customer[]>;
  private selectedFilter: string = 'active';
  private searchString: string = '';
  private showCreateCustomerModal: boolean = false;
  private showImportCustomersModal: boolean = false;

  private readonly screenWidth: string = screen.width - 90 - 72 + 'px';

  @CustomerStore.Getter('customers')
  private _customers!: Customer[];

  get customers(): Customer[] {
    return this._customers;
  }

  public get filteredCustomers() {
    let filteredCustomers: Customer[] = this.customers;

    switch (this.selectedFilter) {
      case 'all':
        break;
      case 'active':
        filteredCustomers = filteredCustomers.filter((customer) => customer.active);
        break;
      default:
        filteredCustomers = filteredCustomers.filter((customer) => !customer.active);
        break;
    }
    return filteredCustomers;
  }

  public closeImportModal(importResponse: any) {
    this.showImportCustomersModal = false;

    if (importResponse) {
      this.loadCustomersAction(this.$route.params.tenantId);
    }
  }

  private mounted() {
    try {
      this.loadCustomersAction(this.$route.params.tenantId);
    } catch (e) {
      this.$notifyErrorSimplified('GENERAL.NOTIFICATIONS.GENERAL_ERROR');
    }
  }
}

